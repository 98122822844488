import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const NikkeGuidesUnionLevels: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Union levels</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_unionlevels.png"
            alt="Union levels"
          />
        </div>
        <div className="page-details">
          <h1>Union levels</h1>
          <h2>Data for Union levels in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Union levels" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>Exp</th>
              <th>Cumulative Exp</th>
              <th>Max Members</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>9K</td>
              <td>9K</td>
              <td>20</td>
            </tr>
            <tr>
              <td>2</td>
              <td>25K</td>
              <td>34K</td>
              <td>25</td>
            </tr>
            <tr>
              <td>3</td>
              <td>50K</td>
              <td>84K</td>
              <td>30</td>
            </tr>
            <tr>
              <td>4</td>
              <td>100K</td>
              <td>184K</td>
              <td>30</td>
            </tr>
            <tr>
              <td>5</td>
              <td>300K</td>
              <td>484K</td>
              <td>30</td>
            </tr>
            <tr>
              <td>6</td>
              <td>900K</td>
              <td>1.38M</td>
              <td>30</td>
            </tr>
            <tr>
              <td>7</td>
              <td>1M</td>
              <td>2.38M</td>
              <td>30</td>
            </tr>
            <tr>
              <td>8</td>
              <td>1.1M</td>
              <td>3.48M</td>
              <td>30</td>
            </tr>
            <tr>
              <td>9</td>
              <td>1.26M</td>
              <td>4.74M</td>
              <td>30</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesUnionLevels;

export const Head: React.FC = () => (
  <Seo
    title="Union levels | NIKKE | Prydwen Institute"
    description="Data for Union levels in NIKKE."
  />
);
